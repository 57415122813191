
import axios from 'axios'
import { defineStore } from 'pinia'
import {useAuthStore} from "./auth";


export const useApiSettingStore = defineStore({
    id: 'apiSettings',

    state: () => ({
        error500: '',
        apiSettings: null,
        overlayModal: false,
        overlayPopup: false,
        overlayFile: false,
        listTypeJob: [],
        typePopupCustom: '',
        fromComponent: '',
        heightHeaderBar: 0,
        errorNetwork: false
    }),
    actions: {
        getApiSettings(){
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().user.token
                    }
                }
                axios.options('api/',config)
                    .then(res => {
                        this.apiSettings = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },

    getters:{

        apiSettingsGetters: state => {
            return state.apiSettings
        },
        heightHeaderBarGetters: state => {
            return state.heightHeaderBar
        },
        overlayModalGetters: state => {
            return state.overlayModal
        },
        fromComponentGetters: state => {
            return state.fromComponent
        },
        typePopupCustomGetters: state => {
            return state.typePopupCustom
        },
        overlayPopupGetters: state => {
            return state.overlayPopup
        },
        overlayFileGetters: state => {
            return state.overlayFile
        },
        listTypeJobGetters: state => {
            return state.listTypeJob
        },
        error500Getters: state => {
            return state.error500
        },
        errorNetworkGetters: state => {
            return state.errorNetwork
        }
    }
})


